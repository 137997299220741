<template>
  <session-print :sessionId="activeSessionId"></session-print>
</template>

<script>
import SessionPrint from "@/components/shared/SessionPrint";

export default {
  components: {
    SessionPrint
  },

  computed: {
    activeSessionId() {
      return this.$store.getters.activeSessionId;
    }
  }

  // mounted() {
  //   if (!this.activeSessionId) return;
  //   this.$store.commit("SET_ACTIVE_SESSION", this.activeSessionId);
  // }
};
</script>
